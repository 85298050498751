<template>
    <v-row>
        <v-col cols="12">
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-btn fab text small @click="$refs.calendario.anterior()"><v-icon small>mdi-chevron-left</v-icon></v-btn>
                    <v-btn fab text small @click="$refs.calendario.proximo()"><v-icon small>mdi-chevron-right</v-icon></v-btn>
                    <v-toolbar-title class="py-2">{{data}}</v-toolbar-title>
                    <v-spacer class="hidden-xs-only"/>
                    <v-row v-if="usuarioSessao.id_grupo == 1 || usuarioSessao.id_grupo == 2">
                        <v-col cols="6"><SelectFranquia dense hide-details label="* Franquia:" flat outlined v-model="filtro.id_franquia" /></v-col>
                        <v-col cols="6"><v-autocomplete dense hide-details label="Turma:" flat outlined :items="selectTurma" v-model="filtro.id_turma" /></v-col>
                    </v-row>
                </v-toolbar>
                <v-card-text>
                    <AulaListar @dataCalendario="atualizarData" ref="calendario" :filtro="filtro" />
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import AulaListar from "@/Views/Aula/Component/AulaListar"
    import axios from "axios"
    import {mapState} from "vuex"
    import SelectFranquia from "@/Views/Estrutura/Form/SelectFranquia.vue"

    export default {
        name: "Aula",
        components: {SelectFranquia, AulaListar},
        computed : {
            ...mapState(['apiUrl', 'usuarioSessao'])
        },
        data() {
            return {
                filtro : {
                    id_franquia : null,
                    id_turma : null
                },
                data : '',
                selectTurma : []
            }
        },
        methods : {
            atualizarData(d) {
                let meses = [
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro'
                ]
                let data = d.split('-')
                this.data = `${meses[parseInt(data[1])-1]} de ${data[0]}`
            },
            selectTurmaListar() {
                if(this.filtro.id_franquia) {
                    return axios.post(`${this.apiUrl}turma/listar`, {id_franquia : parseInt(this.filtro.id_franquia), id_usuario : this.usuarioSessao.id_usuario, limit : 9999}).then((res) => {
                        this.selectTurma = res.data.lista.map((t) => {return {
                            value : parseInt(t.id_turma),
                            text : t.turma
                        }})
                    })
                } else {
                    this.selectTurma = []
                }
            },
            async init() {
                await this.selectTurmaListar()
            }
        },
        watch : {
            'filtro.id_franquia' : function () {
                this.selectTurmaListar().then(() => {
                    this.filtro.id_turma = null
                })
            }
        },
        activated() {
            this.init()
        }
    }
</script>

<style scoped>

</style>
